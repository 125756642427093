<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox2"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox2_energy','公司能源KPI', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <!-- <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        /> -->
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox2
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "carbonBox2",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        // {
        //     code: '按天',
        //     name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        // },
        // {
        //     code: '按周',
        //     name:'按周'
        // },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF", "#B4D0FF"],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },

    //每月报警预警数
    getData() {
      // var deptCode = 'JG221027051'
      const url = Config.dev_url + "/api-apps-v2/api/v2/electric/subterm/kpi_ranking_company";
      var data = {
        code: this.currentSelectDeptInfo.CODE,
        // seltype:"month",//月：month, 年：year
        // time:"2022-05"//月份查： 2022-05，  年份查:2022
      };
      Object.assign(data, this.transformSearch());
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          //console.log(res.data.data);
          this.detailInfo = {};
          var chart1 = {
            categoryData: res.data.data.categoryData.reverse(),
            seriesData: [
              {
                name: this.commonLangHandler('powerBox2_real','实际', this.getZEdata),
                value: res.data.data.seriesData.realEnergy.reverse(),
              },
              {
                name: this.commonLangHandler('powerBox2_plan','计划', this.getZEdata),
                value: res.data.data.seriesData.plannedEnergy.reverse(),
              },
            ],
            top: res.data.data.seriesData.ranking.reverse(),
            freeSpace: res.data.data.seriesData.freeSpace.reverse(),
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData0() {
      this.detailInfo = {};
      var chart1 = {
        categoryData: [
          "厦门ABB公司A",
          "厦门ABB公司B",
          "厦门ABB公司C",
          "厦门ABB公司D",
        ],
        seriesData: [
          {
            name: this.commonLangHandler('powerBox2_real','实际', this.getZEdata),
            value: [1130, 2132, 1247, 2138],
          },
          {
            name: this.commonLangHandler('powerBox2_plan','计划', this.getZEdata),
            value: [1200, 1200, 1700, 1800],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },
    initEchart() {
      let vm = this;
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          emphasis: {
            focus: "series",
          },
          // barGap:0,
          barWidth: 10,
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "9%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "10%",
          icon: "rect",
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (params) {
            let str = "";
            str += params[0].axisValueLabel + "<br>";
            str +=
              "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center;margin-top: 10px'>" +
              vm.LangHandler('rank','排名') +
              "<span style='margin-right: 10px'></span>" +
              vm.detailInfo.chart1.top[params[0].dataIndex] +
              "</div>";
            params.forEach((item, index) => {
              str +=
                "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                "<span>" +
                item.marker +
                item.seriesName +
                "</span>" +
                "<span style='margin-right: 10px'></span>" +
                item.value +
                "</div>";
            });
            str +=
              "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
              vm.LangHandler('remainpercent','结余百分比') +
              "<span style='margin-right: 10px'></span>" +
              vm.detailInfo.chart1.freeSpace[params[0].dataIndex] +
              "</div>";
            return str;
          },
        },
        xAxis: {
          type: "value",
          name: "(kWh)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          name: this.commonLangHandler('powerBox2_company','(公司)', this.getZEdata),
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#696969",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox2 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
